import React from "react";
import * as Styled from "./style";

interface Props {
  gridArea: string;
  title: string;
  value: string;
  setValue: (value: string) => void;
}

const DynamicTextInput = ({ gridArea, title, value, setValue }: Props) => {
  const onChangeCallback = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setValue(event.currentTarget.value);
  };
  return (
    <Styled.TextInputContainer gridArea={gridArea}>
      <Styled.TextInputTitle htmlFor={title}>{title}</Styled.TextInputTitle>
      <Styled.TextInput
        name={title}
        required
        value={value}
        onChange={onChangeCallback}
      />
    </Styled.TextInputContainer>
  );
};

export default DynamicTextInput;
