import React from "react";

const SMALL_SCREEN_BREAK = 800;

export const useSmallScreenListener = () => {
  const [smallScreen, setSmallScreen] = React.useState(false);

  const setScreen = () => {
    if (window.innerWidth <= SMALL_SCREEN_BREAK) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  React.useEffect(() => {
    setScreen();
    window.addEventListener("resize", setScreen);
    return () => {
      window.removeEventListener("resize", setScreen);
    };
  });

  return smallScreen;
};
