import styled from "@emotion/styled";
import BackgroundImage from "../../assets/CitySplash.jpg";

const ContactUs_Small = `
  grid-template-columns: 100%;
  grid-template-rows: auto 70%;
  grid-template-areas: "header" "input";
`;

const ContactUs_Large = `
  grid-template-columns: 10% 30% 10% 50%;
  grid-template-rows: 100%;
  grid-template-areas: ". header . input";
`;

export const ContactUs = styled.div<{ smallScreen: boolean }>`
  width: 100%;
  height: 100%;
  background-color: white;
  display: grid;
  ${(props) => (props.smallScreen ? ContactUs_Small : ContactUs_Large)}
`;

const ContactUsHeader_Small = `
  padding-top: 64px;
  padding-left: 32px;
  padding-right: 32px;
`;

const ContactUsHeader_Large = `
    padding-top: 128px;
`;

export const ContactUsHeaderContainer = styled.div<{ smallScreen: boolean }>`
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.smallScreen ? ContactUsHeader_Small : ContactUsHeader_Large}
`;

export const ContactUsHeader = styled.div<{ smallScreen: boolean }>`
  color: black;
  font-size: ${(props) => (props.smallScreen ? "24px" : "36px")};
  font-family: helvetica, arial, sans-serif;
  font-weight: 600;
  width: 100%;
`;

export const ContactUsBody = styled.div<{ smallScreen: boolean }>`
  color: black;
  font-size: ${(props) => (props.smallScreen ? "16px" : "24px")};
  font-family: helvetica, arial, sans-serif;
  font-weight: normal;
`;

export const AboutUsBackgroundImage = styled.div`
  grid-area: image;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const ContactUsFormContainer = styled.div<{ smallScreen: boolean }>`
  grid-area: input;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${(props) => (props.smallScreen ? "0" : "128px")};
`;

export const FormArea = styled.form<{ smallScreen: boolean }>`
  display: grid;
  width: ${(props) => (props.smallScreen ? "75%" : "60%")};
  height: ${(props) => (props.smallScreen ? "85%" : "75%")};
  gap: 8px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 15% 15% 15% 15% 30% 10%;
  grid-template-areas:
    "storageCompanyName storageCompanyName"
    "city state"
    "squareFootage numberOfUnits"
    "email email"
    "textArea textArea"
    "submit submit";
`;

export const SubmitButtonContainer = styled.div`
  grid-area: submit;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.input`
  background-color: blue;
  border: none;
  height: 100%;
  width: 128px;
  background: linear-gradient(
    112.68deg,
    rgba(255, 75, 75, 0.8) 0%,
    rgba(252, 176, 69, 0.8) 100%
  );
  border: 1px solid white;
  cursor: pointer;
  font-size: 18px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 600;
  border-radius: 6px;
  color: white;

  &:hover {
    background: white;
    border: 1px solid rgba(255, 75, 75, 0.8);
    color: rgba(255, 75, 75, 0.8);

    transition: background 5s ease;
  }
`;
