import * as React from "react";
import iPhoneHero from "../../assets/iphone-12--black.png";
import * as Styled from "./style";
import { WML } from "../../assets/logos/WML";
import Button from "../../components/button/button";
import { ButtonSizes, ButtonTypes } from "../../components/button/buttonUtils";
import { useSmallScreenListener } from "../../hooks/useSmallScreenListener";

const ProductScreen = () => {
  const smallScreen = useSmallScreenListener();

  return (
    <Styled.Product id="productSection" smallScreen={smallScreen}>
      <Styled.ProductSplash>
        <img
          style={{ width: "auto", maxHeight: "90%" }}
          alt="Watch My Lock"
          src={iPhoneHero}
        />
      </Styled.ProductSplash>
      <Styled.ProductSplashTextContainer>
        <Styled.ProductSplashText smallScreen={smallScreen}>
          <WML />
          <strong>Watch My Lock</strong> uses advanced sensor technology to
          ensure that your storage unit is only accessed by the right people.
          <br />
          <br />
          Our sensors can be deployed into almost any storage facility and
          immediately go to work empowering your customers to take better
          control of their things.
        </Styled.ProductSplashText>
        <Button
          text="Check it out"
          buttonType={ButtonTypes.Dark}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            window.location.href = "https://watchmylock.com";
          }}
        />
      </Styled.ProductSplashTextContainer>
    </Styled.Product>
  );
};

export default ProductScreen;
