import styled from "@emotion/styled";

export const TextInputContainer = styled.div<{ gridArea: string }>`
  grid-area: ${(props) => props.gridArea};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

export const TextInputTitle = styled.label`
  color: black;
  font-size: 18px;
  font-family: helvetica, arial, sans-serif;
  font-weight: normal;
  padding-bottom: 8px;
  width: 100%;
  text-align: start;
`;

export const TextInput = styled.input`
  outline: 0;
  display: inline-block;
  width: calc(100% - 34px);
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  margin-right: 0px;
  font-family: helvetica, arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: black;
  border: 1px solid gray;
  border-radius: 6px;
  /* transition: background 270ms ease; */
  &:hover {
    border: 1px solid #ff4b4bc1;
    transition: border 270ms ease;
  }

  &:focus {
    background: linear-gradient(
      112.68deg,
      rgba(255, 75, 75, 0.2) 0%,
      rgba(252, 176, 69, 0.2) 100%
    );
    border: 1px solid #ff4b4bc1;
    transition: background 300ms ease-in-out;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* &:active {
    background: linear-gradient(
      112.68deg,
      rgba(255, 75, 75, 0.3) 0%,
      rgba(252, 176, 69, 0.3) 100%
    );
    transition: background 270ms ease;
  } */
`;

export const ErrorText = styled.div`
  width: 100%;
  margin-top: 2px;
  color: red;
  font-family: helvetica, arial, sans-serif;
  font-size: 14px;
`;
