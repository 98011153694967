import styled from "@emotion/styled";

const FooterContainer = styled.div`
  max-height: 64px;
  width: 100%;
  background-color: #636569;
  color: white;
  font-size: 16px;
  font-family: serif;
  font-weight: normal;
  user-select: none;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  return <FooterContainer>© 2022 Storage Monitor Group LLC</FooterContainer>;
};

export default Footer;
