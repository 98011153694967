import React from "react";
import * as Styled from "./style";

interface Props {
  gridArea: string;
  title: string;
  inputType: string;
  value: string;
  setValue: (value: string) => void;
}

const StaticTextInput = ({
  gridArea,
  title,
  inputType,
  value,
  setValue,
}: Props) => {
  const validationCallback = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputType === "number") {
      const { key } = event;
      if (key.match(/^[A-Za-z]{1}$/i)) {
        event.preventDefault();
      }
    }
  };
  const onChangeCallback = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };
  return (
    <Styled.TextInputContainer gridArea={gridArea}>
      <Styled.TextInputTitle htmlFor={title}>{title}</Styled.TextInputTitle>
      <Styled.TextInput
        name={title}
        required
        type={inputType}
        value={value}
        min={0}
        onChange={onChangeCallback}
        onKeyDown={validationCallback}
      />
    </Styled.TextInputContainer>
  );
};

export default StaticTextInput;
