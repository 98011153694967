import styled from "@emotion/styled";
import { css } from "@emotion/css";

const Product_Large = `
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  grid-template-areas: "product description";
`;

const Product_Small = `
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "product" "description";
`;

export const Product = styled.div<{ smallScreen: boolean }>`
  background-color: white;
  width: 100%;
  height: 100%;
  display: grid;

  ${(props) => (props.smallScreen ? Product_Small : Product_Large)}
`;

export const ProductSplash = styled.div`
  grid-area: product;
  /* background: linear-gradient(112.68deg, #ff4b4bc1 0%, #fcb045c1 100%); */
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: #b1bfd8; */
  /* background-image: linear-gradient(315deg, #b1bfd8 0%, #6782b4 74%); */
`;

export const ProductIphoneContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ProductSplashTextContainer = styled.div`
  grid-area: description;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: black; */
`;

export const ProductSplashText = styled.div<{ smallScreen: boolean }>`
  /* color: white; */
  font-size: ${(props) => (props.smallScreen ? "16px" : "24px")};
  font-family: helvetica, arial, sans-serif;
  font-weight: normal;
  user-select: none;
  text-align: center;
  padding: ${(props) => (props.smallScreen ? "32px" : "15%")};
  padding-bottom: 32px;
`;

export const ProductHeader = styled.div`
  grid-area: header;
  font-size: 24px;
  font-family: helvetica, arial, sans-serif;
  font-weight: normal;
  user-select: none;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
