import { DownCarrot } from "../../assets/icons/downCarrot";
import * as Styled from "./style";
import Product from "../Product";
import ContactUs from "../Contact";
import Footer from "../../components/footer";

const Home = () => {
  return (
    <Styled.HomeContainer>
      <Styled.Splash>
        <Styled.SplashText>
          We help protect what <strong>matters</strong>
        </Styled.SplashText>
        <Styled.DownCarrotContainer
          onClick={() =>
            document.getElementById("productSection")?.scrollIntoView()
          }
        >
          <DownCarrot />
        </Styled.DownCarrotContainer>
      </Styled.Splash>
      <Product />
      <ContactUs />
      <Footer />
    </Styled.HomeContainer>
  );
};

export default Home;
