import React from "react";
import * as Styled from "./style";
import StaticTextInput from "../../components/input/textInput";
import DynamicTextInput from "../../components/input/dynamicTextInput";
import { useSmallScreenListener } from "../../hooks/useSmallScreenListener";

const ContactUs = () => {
  const [companyName, setCompanyName] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [sqFt, setSqFt] = React.useState<string>("");
  const [numUnits, setNumUnits] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [comments, setComments] = React.useState<string>("");
  const smallScreen = useSmallScreenListener();

  const onSubmitCallback = (event: React.SyntheticEvent<HTMLFormElement>) => {
    console.log(event);
    event.preventDefault();

    // Send email

    setCompanyName("");
    setCity("");
    setState("");
    setSqFt("");
    setNumUnits("");
    setEmail("");
    setComments("");
  };

  return (
    <Styled.ContactUs id="contactSection" smallScreen={smallScreen}>
      <Styled.ContactUsHeaderContainer smallScreen={smallScreen}>
        <Styled.ContactUsHeader smallScreen={smallScreen}>
          Contact Us
        </Styled.ContactUsHeader>
        <Styled.ContactUsBody smallScreen={smallScreen}>
          Send us some information about your property and one of our
          representatives will get back to you promptly.
        </Styled.ContactUsBody>
      </Styled.ContactUsHeaderContainer>
      <Styled.ContactUsFormContainer smallScreen={smallScreen}>
        <Styled.FormArea onSubmit={onSubmitCallback} smallScreen={smallScreen}>
          <StaticTextInput
            gridArea={"storageCompanyName"}
            title={"Company Name"}
            inputType={"text"}
            value={companyName}
            setValue={setCompanyName}
          />
          <StaticTextInput
            gridArea={"city"}
            title={"City"}
            inputType={"text"}
            value={city}
            setValue={setCity}
          />
          <StaticTextInput
            gridArea={"state"}
            title={"State"}
            inputType={"text"}
            value={state}
            setValue={setState}
          />
          <StaticTextInput
            gridArea={"squareFootage"}
            title={"Square feet"}
            inputType={"number"}
            value={sqFt}
            setValue={setSqFt}
          />
          <StaticTextInput
            gridArea={"numberOfUnits"}
            title={"Number of units"}
            inputType={"number"}
            value={numUnits}
            setValue={setNumUnits}
          />
          <StaticTextInput
            gridArea={"email"}
            title={"Email"}
            inputType={"email"}
            value={email}
            setValue={setEmail}
          />
          <DynamicTextInput
            gridArea={"textArea"}
            title={"Comments"}
            value={comments}
            setValue={setComments}
          />
          <Styled.SubmitButtonContainer>
            <Styled.SubmitButton type="submit" value="Submit" />
          </Styled.SubmitButtonContainer>
        </Styled.FormArea>
      </Styled.ContactUsFormContainer>
    </Styled.ContactUs>
  );
};

export default ContactUs;
