import React from "react";
import { IconNames } from "../../assets/icons";

import Button from "./../button/button";
import { ButtonSizes, ButtonTypes } from "./../button/buttonUtils";
import {
  MenuBarContainer,
  ButtonContainer,
  Logo,
  LogoContainer,
  SmallMenuBarContainer,
  MenuExpansionButton,
  SmallMenuDivider,
  SmallButtonContainer,
} from "./menuBarStyled";

const SMALL_SCREEN_BREAK = 800;

const Menubar = () => {
  const [smallScreen, setSmallScreen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const menuLogo = require("../../assets/SMGLogo.png");

  const setScreen = () => {
    if (window.innerWidth <= SMALL_SCREEN_BREAK) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  React.useEffect(() => {
    setScreen();
    window.addEventListener("resize", setScreen);
    return () => {
      window.removeEventListener("resize", setScreen);
    };
  });

  return smallScreen ? (
    <>
      <SmallMenuBarContainer menuOpen={menuOpen}>
        <LogoContainer>
          {/* <Logo
            src={menuLogo}
            alt="Senior Class Council"
            aria-label="Senior Class Council"
          /> */}
        </LogoContainer>

        <MenuExpansionButton>
          <Button
            buttonType={ButtonTypes.Primary}
            buttonSize={ButtonSizes.Small}
            onClickHandler={(event: React.MouseEvent) => {
              setMenuOpen(!menuOpen);
            }}
            style={{
              maxWidth: "40px",
            }}
            preventColorInversionPreset
            iconName={menuOpen ? IconNames.Close : IconNames.Menu}
          />
        </MenuExpansionButton>
        {menuOpen && (
          <>
            <SmallButtonContainer gridArea={"home"}>
              <Button
                text="Products"
                buttonType={ButtonTypes.Primary}
                buttonSize={ButtonSizes.Large}
                onClickHandler={(event: React.MouseEvent) => {
                  console.log(event);
                }}
                preventColorInversionPreset
              />
            </SmallButtonContainer>
            <ButtonContainer
              gridRowStart={3}
              gridRowEnd={3}
              gridColumnStart={1}
              gridColumnEnd={2}
            >
              <SmallMenuDivider />
            </ButtonContainer>
            <SmallButtonContainer gridArea={"about"}>
              <Button
                text="Contact"
                buttonType={ButtonTypes.Primary}
                buttonSize={ButtonSizes.Large}
                onClickHandler={(event: React.MouseEvent) => {
                  console.log(event);
                }}
                preventColorInversionPreset
              />
            </SmallButtonContainer>
          </>
        )}
      </SmallMenuBarContainer>
    </>
  ) : (
    <MenuBarContainer>
      <LogoContainer>
        <Logo
          src={menuLogo}
          alt="Senior Class Council"
          aria-label="Senior Class Council"
          onClick={() => {}}
        />
      </LogoContainer>
      <ButtonContainer
        gridRowStart={1}
        gridRowEnd={1}
        gridColumnStart={4}
        gridColumnEnd={4}
      >
        <Button
          text="Products"
          buttonType={ButtonTypes.Primary}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            const element = document.getElementById("productSection");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          preventColorInversionPreset
        />
      </ButtonContainer>
      <ButtonContainer
        gridRowStart={1}
        gridRowEnd={1}
        gridColumnStart={5}
        gridColumnEnd={5}
      >
        <Button
          text="Contact"
          buttonType={ButtonTypes.Primary}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            const element = document.getElementById("contactSection");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          preventColorInversionPreset
        />
      </ButtonContainer>
    </MenuBarContainer>
  );
};

export default Menubar;
