import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const bounce = keyframes`
  0% {
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  70% { 
      transform: translate3d(0,30px,0);
      opacity: .7;
    }
    to { 
      transform: translate3d(0,30px,0);
      opacity: 0;
    }
`;

export const HomeContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Splash = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    112.68deg,
    rgba(255, 75, 75, 0.757) 0%,
    rgba(252, 176, 69, 0.757) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  cursor: default;
`;

export const DownCarrotContainer = styled.div`
  position: absolute;
  bottom: 64px;
  opacity: 0;
  animation: ${bounce} 8s ease infinite;
  animation-delay: 5s;
  cursor: pointer;
`;

export const SplashText = styled.div`
  font-size: 64px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 200;
  user-select: none;
  padding: 32px;
`;
